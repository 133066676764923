import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Scrollbar from 'react-scrollbars-custom';
import InfiniteScroll from 'react-infinite-scroll-component';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { checkPrivileges } from '../../../Helpers'
import Clip from '../../../assets/nav/clip.svg'
import Loader from './../../Loader'
import AddRecourcesGroup from '../../../Components/Recources/AddRecourcesGroup';
import Collapse from "@material-ui/core/Collapse"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ChevronRight"
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Translate } from 'react-localize-redux';
import ConfirmDialoag from '../../../Components/common/ConfirmDialoag';
import Notifications from 'react-notification-system-redux';
import { resetBounds, setTrackId, toggleAllDevices, toggleAllGroupDevices, updateDeviceVisible } from '../../../Actions/Devices';

class RecourcesList extends Component {
    constructor(props) {
        super(props);
        this.state={
          items: {}
        }
    }



    render() {
      
      const endMessage =
        this.props.resources && this.props.resources.total > 0 ? null : (
          <p style={{ textAlign: 'center', color: '#ccc' }}>
            {this.props.translate('notFound')}
          </p>
        )
        return (
            <div>
               <div className='sidebar-users-list'>
            <Scrollbar
              disableTracksWidthCompensation={true}
              onScroll={this.props.view==="groups"?this.props.handleScrollGroups:null}
              scrollerProps={{
                renderer: props => {
                  const { elementRef, ...restProps } = props
                  return (
                    <div {...restProps} ref={elementRef} id='scrollableVehicle' />
                  )
                }
              }}
            >
              {checkPrivileges('itemgroup') && this.props.resources && this.props.resources.loading ? <p style={{ color: '#ccc' }}><Loader defaultStyle={true} /></p> : (
                <InfiniteScroll
                  dataLength={this.props.resources.data.length}
                  next={this.props.nextResources}
                  // hasMore={this.props.itemPagination.hasNext}
                  // loader={<Loader defaultStyle={false} />}
                  scrollableTarget='scrollableVehicle'
                  endMessage={endMessage}
                >
                <div className="group-view list-row-condest">
                  <ul className='list-view-group'>
                      <ListRow
                        editOption={this.state.editOption}
                        {...this.props}
                      />
                      </ul>
                </div>
                </InfiniteScroll>
               )}
            </Scrollbar>
          </div>
            </div>
        );
    }
}
class ListRow1 extends Component {
    constructor(props) {
      super(props);
      this.state = {collapseId: 0, collapseIds: {},isChecked:true }
      this.toggleAllDevices = this.toggleAllDevices.bind(this)
    }

    // toggleAllDevices (event) {
      
    //   // console.log("e===", );
    //   this.props.dispatch(toggleAllDevices({ checked: event.target.checked } ));
    //   // this.props.dispatch(toggleAllDevices({ checked: event.target.checked } ));
    //   this.setState({
    //     isChecked: event.target.checked
    //   })
    // }
    toggleAllDevices(groupId, event) {
      // console.log("Grp=====", groupId, event.target.checked);
      const isChecked = event.target && event.target.checked;
      // console.log("res====",this.props.fromArray );
    
      if (isChecked !== null) {
        this.props.dispatch(toggleAllGroupDevices({ checked: isChecked }, true , this.props.fromArray ));
        this.setState((prevState) => ({
          collapseIds: {
            ...prevState.collapseIds,
            [groupId]: {
              ...prevState.collapseIds[groupId],
              isChecked: isChecked,
            },
          },
        }));
      }
    }
    

    collapseGroup = collapseId => {
      // console.log("res====",this.props.fromArray );
      if(this.state.collapseId !== collapseId) {
        this.setState({collapseId}, () => {
          this.props.fetchNestedItems(collapseId, 1)
        })
      }
      else {
        this.setState({collapseId: 0});
      }
    }
    confirmDelete = deleteSelection => {
      this.setState({openConfirm: true, deleteSelection})
    }

    resetDelete = () => {
      this.setState({openConfirm: false, deleteSelection: ''})
    }

    deleteNow = () => {
      if(this.state.deleteSelection) {
        this.props.deleteResource(this.state.deleteSelection.id, (response) => {
          if(response.state === 200) {
            this.props.dispatch(Notifications.success({
              message: <Translate id='groupDeleted' />,
              autoDismiss: 10
            }));
          }
          this.resetDelete();
        });
      }
    }

    render () {
      const { ItemChild, ItemChildProps, fromArray, exact, itemType} = this.props;
        return <>
        {this.state.openConfirm && <ConfirmDialoag onCancel={this.resetDelete} onOk={this.deleteNow} title={<Translate id="areYouWantToDelete" />}>
        {this.state.deleteSelection.name}
        </ConfirmDialoag>}
        {this.props.resources.data.map(item =><li>
        <div className='clearfix group-list-row list-row'>
          
            <label className='checkbox' onClick={e => this.collapseGroup(item.id)} >
                <span
                className='unit-name'
                style={{ minWidth: 200, maxWidth: 200, marginLeft: 0 }}
                >
                {item.id === this.state.collapseId ? <ExpandMoreIcon style={{width: 20, height: 20, verticalAlign: 'middle', marginRight: 5}} /> : <ExpandLessIcon style={{width: 20, height: 20, verticalAlign: 'middle', marginRight: 5}} />}
                {item.name}
                {"       "}{"  -  "}{"     "}
                {item?.itemCount|| "  "}
                </span>
            </label>
              <div className='pull-right'>
                {checkPrivileges('itemgroupDelete') && (
                    <span
                    className='action-item hovered'
                    title={this.props.translate('delete')}
                      onClick={() => this.confirmDelete(item)}
                    >
                    <DeleteIcon style={{fontSize: 16}}/>
                    </span>
                )}
                {checkPrivileges('itemgroupUpdate') && !this.props.disableEdit && (
                  <span
                    className='action-item hovered'
                    title={this.props.translate('edit')}
                    onClick={() => this.props.onEditResource(item)}
                  >
                    <EditIcon style={{fontSize: 16}}/>
                  </span>
                )}
                {checkPrivileges('itemgroupLinkItem') && (
                  <span
                    className='action-item hovered'
                    title={this.props.translate('edit')}
                    onClick={() => this.props.onLinkResource(item)}
                  >
                    {/* <Clip style={{fontSize: 16}}/> */}
                    <svg fill='currentColor' width={14} height={14}>
                      <use xlinkHref={`${Clip}#icon`} />
                    </svg>
                  </span>
                )}
              </div>
          </div>
          <Collapse style={{display: 'block'}} in={item.id === this.state.collapseId} timeout='auto' unmountOnExit>
          <div style={{height: 200}}>
            {this.props.nestedResources && this.props.nestedResources.loading && item.id === this.state.collapseId ? <p style={{ color: '#ccc' }}><Loader defaultStyle={true} /></p> : null}
            {this.props.nestedResources && !this.props.nestedResources.loading ? <Scrollbar disableTracksWidthCompensation={true}>
              {itemType === 'Device' ? (this.props.nestedResources.total > 0 && fromArray && fromArray.length ?
                 <ul className="list-view list-view-children">
               {this.props && this.props.monitoring  && (    
                  <li
                      style={{
                        paddingRight: 0,
                        padding: "3px 0 3px 0px",
                        paddingLeft: 0
                      }}
                    >
                      <label
                        className='checkbox'
                        style={{ display: 'inline-block' }}
                      >
                        <input
                          type="checkbox"
                          name={`isChecked_${item.id}`}
                          // checked={this.state.collapseIds[item.id]?.isChecked || ''}
                          checked={this.state.collapseIds[item.id]?.isChecked === undefined ? true : this.state.collapseIds[item.id]?.isChecked}

                          onChange={(event) => this.toggleAllDevices(item.id, event)}
                        />
                        {/* <input
                          type='checkbox'
                          name='isChecked'
                          checked={this.state.isChecked}
                          onChange={this.toggleAllDevices}
                        /> */}
                        <span className='theme-input-checkbox'>
                          <CheckBoxIcon
                            className='on-active material-icons'
                            style={{fontSize:22, color:'inherit'}}
                          />
                          <CheckBoxOutlineBlankIcon
                            className='on-inactive material-icons'
                            style={{fontSize:22, color:'inherit'}}
                          />
                            <label   style={{marginLeft:"10px"}}> Select All </label> 
                        </span>
                     
                      </label>
                    </li>)} 


                    {fromArray.map(e =>
                     <ItemChild 
                     item={this.props.nestedResources.data.find(row => row.id === e.id) ? 
                             e : 
                           (exact ? null: e)}
                           {...ItemChildProps} />
                         )}


                  {/* {fromArray.map(e =>
                     <ItemChild item={this.props.nestedResources.data.find(row => row.id === e.id) 
                     ? this.props.nestedResources.data.find(row => row.id === e.id) : (exact ? null: e)} 
                     {...ItemChildProps} />)} */}
                     
                     </ul> :
                      <>
                      <p style={{ textAlign: 'center', color: '#ccc' }}>

              <Translate id='notFound' />
            </p></>) : null}
              {itemType !== 'Device' ? (this.props.nestedResources.total > 0 ? this.props.nestedResources.data.map(e => <ItemChild item={fromArray && fromArray.find(row => row.id === e.id) ? fromArray.find(row => row.id === e.id) : (exact ? null: e)} {...ItemChildProps} />) : <><p style={{ textAlign: 'center', color: '#ccc' }}>
              <Translate id='notFound' />
            </p></>) : null}
            </Scrollbar> : null}
          </div>
          </Collapse>
         </li>
        )}</>
    }
  }
export const ListRow = (ListRow1)
export default RecourcesList;