import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MainUnitModal from './MainUnitModal'
import { connect } from 'react-redux'
import isEqual from 'react-fast-compare'
import { withLocalize } from 'react-localize-redux'
import { removeDevice, resetFilters } from './../../Actions/Devices'
import Layout from '../../Layout'
import { withSnackbar } from 'notistack'
import { getGroups, saveGroupWiseDevices } from '../../Actions/Groups'
import EmptyState from '../../Components/common/EmptyState'
import { DeviceModal } from '../../Components/Devices/deviceModal'
import { checkPrivileges, errorHandler } from '../../Helpers/index'
import './Style.scss'
import { withStyles } from '@material-ui/core/styles'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag'
import Notifications from 'react-notification-system-redux'
import withResources from '../HOCRecources'
import ResourceModal from '../../Components/Recources/resourceModal'
import instance from '../../axios'
import axios from 'axios'
let cancelTokenSource = null;
const styles = theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  tab: {
    background: '#ebebeb',
    marginLeft: 5,
    borderRadius: '4px 4px 0 0'
  },
  selected: {
    background: '#fff',
    color: '#333 !important'
  },
  scrollButtons: {
    color: '#333'
  },
  indicator: {
    backgroundColor: '#333'
  }
})
const deviceDataFormat = {
  id: '',
  attributes: '',
  name: '',
  uniqueId: '',
  status: '',
  disabled: '',
  lastUpdate: '',
  positionId: '',
  groupId: '',
  phone: '',
  model: '',
  contact: '',
  category: '',
  geofenceIds: ''
}

class Units extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //comman for all
      isNewAddEnable: false,
      activeOperation: '',
      slectedUnits: '',
      // all fields of form
      resFormData: { ...deviceDataFormat },
      resourceList: false,
      linkResource: false,
      pageResource: 1,
      limitResource: 50,
      initFetch: false,
      currentPage2: 1,
      pageSize2: 50,
      itemPagination2: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText2: ''
      },
      searchText2: ''
    }

    this.addNewDevice = this.addNewDevice.bind(this)
    this.addNewGroup = this.addNewGroup.bind(this)
    this.removeDevice = this.removeDevice.bind(this)
  }

  removeDevice() {
    let { slectedUnits } = this.state
    // fetch(`/api/devices/${slectedUnits.id}`, {
    //   method: 'DELETE',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   }
    // })
    instance({
      url: `/api/devices/${slectedUnits.id}`,
      method: 'DELETE'
    })
      .then(response => {
        // if (response.status === 204) {
        this.props.dispatch(
          Notifications.success({
            message: this.props.translate('trackersIsDeleted'),
            autoDismiss: 10
          })
        )
        this.props.dispatch(removeDevice(slectedUnits.id))
        this.props.history.push('/units')
        this.setState({
          onDeleteConfirmationUnits: false
        })
        // }
      }).catch(error => { errorHandler(error, this.props.dispatch) })
  }

  addNewGroup() {
    this.props.history.push('/units/add/group')
    /*
    this.setState({
      isNewAddEnable: true,
      activeOperation: 'add',
      isVisable: false,
    })*/
  }

  componentWillReceiveProps(n) {
    if (!['addResource', 'editResource'].includes(this.state.activeOperation)) {
      if (n.addUnitScreen) {
        this.setState({
          isNewAddEnable: true,
          activeOperation: 'add',
        })
      } else if (n.match.params.id) {
        this.setState({
          isNewAddEnable: false,
          activeOperation: 'edit'
        })
      }
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state)
  }
  // new code for changing attribute

  addNewDevice() {
    // console.log('new device =')
    this.props.history.push('/units/add/unit')
    /*this.setState({
      isNewAddEnable: true,
      activeOperation: 'add'
    })*/
  }

  checkedDeviceLimit = () => {
    this.setState({
      checkeDeviceLimit: true
    })
  }
  checkedDeviceLimitBackCall = () => {
    this.setState({
      checkeDeviceLimit: false
    })
  }

  onRemovedDevice = (e, item) => {
    e.preventDefault()
    this.setState({
      slectedUnits: item,
      onDeleteConfirmationUnits: true
    })
    return false
  }

  onCloseModal = () => {
    this.setState({
      isNewAddEnable: false,
      activeOperation: ''
    })
  }

  onCancel = () => {
    this.setState({
      onDeleteConfirmationNotification: false,
      onDeleteConfirmationAttributes: false,
      onDeleteConfirmationMaintenance: false,
      onDeleteConfirmationCommand: false,
      onDeleteConfirmationUnits: false
    })
  }

  fetchMoreItems2 = (page, searchText) => {
    this.setState(
      {
        searchText2: ''
      },
      () => {
        this.fetchData2(
          this.state.pageResource,
          this.state.limitResource,
          this.props.logInUser,
          true
        )
      }
    )
  }

  resetFrom = () => {
    this.setState({
      activeOperation: ''
    })
  }


  showResources = (resourceList) => {
    if (!resourceList) {
      this.props.dispatch(saveGroupWiseDevices([]))
      this.props.dispatch(resetFilters())
    }
    this.props.callOnMount()
    this.setState({
      resourceList,
      isVisable: false,
      showItemDetail: '',
      activeOperation: '',
    })
  }
  addResource = () => {
    this.setState({
      isVisable: true,
      showItemDetail: false,
      activeOperation: 'addResource',
      selecteditem: '',
      driverAddress: '',
      driverLat: '',
      driverLon: ''
    })
  }
  onEditResource = (item) => {
    this.setState({
      isVisable: true,
      showItemDetail: false,
      activeOperation: 'editResource',
      selecteditem: item,
      driverAddress: '',
      driverLat: '',
      driverLon: ''
    })
  }
  onLinkResource = (item) => {
    this.setState({
      linkResource: true,
      selecteditem: item,
    }, () => { this.props.fetchNestedItems(item.id, 1) })
  }
  onCloseResource = () => {
    this.setState({
      linkResource: false,
    });
    this.onCloseModal();
  }



  searchItems2 = (text) => {
    this.setState(
      {
        searchText2: text,
        pageResource: 1,
      },
      () => {
        this.fetchData2(this.state.pageResource, this.state.limitResource, this.props.logInUser, true);
      }
    );
  }

  fetchData2 = (page, limit, logInUser, reset = false) => {
    if(cancelTokenSource) {
      cancelTokenSource.cancel("");      
    }

    cancelTokenSource = axios.CancelToken.source();
  
    let searchText = this.state.searchText2 || '';
    
    if (reset || searchText) {
      this.setState({
        itemPagination2: { ...this.state.itemPagination2, items: [] },
        loading: true
      });
    }

    instance({
      url: `/api/groups/get`,
      method: 'GET',
      cancelToken: cancelTokenSource.token,
      params: {
        page: page,
        limit: limit,
        search: searchText
      }
    })
      .then(response => {
        const totalGroupsData = response?.total;
        const getGroupsData = response?.data;
  
        if (getGroupsData) {
          this.setState(prevState => {
            const combinedGroups = reset || searchText
              ? getGroupsData
              : [...prevState.itemPagination2.items, ...getGroupsData];
            const uniqueGroups = combinedGroups.filter((group, index, self) =>
              index === self.findIndex((g) => g.id === group.id)
            );
  
            return {
              itemPagination2: {
                ...prevState.itemPagination2,
                items: uniqueGroups
              },
              loading: false,
              hasMore: uniqueGroups.length < totalGroupsData,
            };
          }, () => {
            this.props.dispatch(getGroups(this.state.itemPagination2?.items));
          });
  
        } else {
          this.setState({
            loading: false,
            hasMore: false,
          });
        }
  
      }).catch(error => {
        console.error(error);
      });
  }
  



  switchToResource=()=>{
this.setState((prevState)=>({
  ...prevState,
  pageResource:1,
  limitResource:50
}))
  }
  handleScrollResources = (values) => {
    const { scrollTop, clientHeight, scrollHeight } = values;
    const { page, limitResource, hasMore } = this.state;
    const { logInUser } = this.props;
    if (hasMore) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.setState(
          (prevState) => {
            const nextPage = prevState.pageResource + 1;
            return { pageResource: nextPage };
          },
          () => {
            this.fetchData2(this.state.pageResource, limitResource, logInUser);
          }
        );
      }
    }
  }
  removeDuplicates = (n, o) => {
    let items = []
    o.map(j => {
      let found = n.data.find(e => e.id === j.id)
      if (found) {
        items.push(found)
      }
      return null
    })

    if (!items.length) {
      this.setState(
        {
          itemPagination2: {
            ...n,
            items: o.concat(n.data)
          },
          currentPage2: n.hasNext ? n.page + 1 : n.page
        },
        () => {
      
          this.props.dispatch(getGroups(o.concat(n.data)))
        }
      )
    }
  }



  componentWillUnmount() {
    this.setState({
      isNewAddEnable: false,
      activeOperation: '',
      slectedUnits: '',
      resFormData: { ...deviceDataFormat },

      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      resourceList: false,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: ''
      },
      currentPage2: 1,
      pageSize2: 50,
      searchText2: '',
      itemPagination2: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText2: ''
      }
    })
  }

  render() {
    if (checkPrivileges('device') || checkPrivileges('group')) {
      const endMessage =
        this.props.itemPagination && this.props.itemPagination.total > 0 ? (
          <p style={{ textAlign: 'center', color: '#ccc' }}>
            {' '}
            -- {this.props.translate('end')} --{' '}
          </p>
        ) : (
          <p style={{ textAlign: 'center', color: '#ccc' }}>
            {' '}
            {this.props.translate('notFound')}{' '}
          </p>
        )
      return (
        <Layout
          {...this.props}
          removeDevice={this.onRemovedDevice}
          resetFrom={this.resetFrom}
          addDevice={this.addNewDevice}
          checkedDeviceLimit={this.checkedDeviceLimitBackCall}
          checkeDeviceLimit={this.state.checkeDeviceLimit}
          classFromChildren='has-padding'
          goAddGroup={this.addNewGroup}
          fetchMoreItems={this.fetchMoreItems}
          itemPagination={{ ...this.state.itemPagination, endMessage }}
          searchItems={this.searchItems}
          fetchMoreItems2={this.fetchMoreItems2}
          itemPagination2={{ ...this.state.itemPagination2 }}
          searchItems2={this.searchItems2}
          showResources={this.showResources}
          onEditResource={this.onEditResource}
          onLinkResource={this.onLinkResource}
          addResource={this.addResource}
          resourceList={this.state.resourceList}
          handleScrollResources={this.handleScrollResources}
          switchToResource={this.switchToResource}
        >
          {!['addResource', 'editResource'].includes(this.state.activeOperation) ? <div
            className='main-content-page'
            style={{
              background: this.props.themecolors.backgroundColor,
              color: this.props.themecolors.textColor,
              borderRadius: 6
            }}
          >
            {this.state.onDeleteConfirmationUnits && (
              <ConfirmDialoag
                onCancel={this.onCancel}
                onOk={this.removeDevice}
                title={this.props.translate('areYouWantToDelete')}
                children={this.state.slectedUnits.name}
              />
            )}

            {this.state.isNewAddEnable && this.props.ServerSetting ? (
              <DeviceModal
                {...this.props}
                logInUser={this.props.logInUser}
                onCloseModal={this.onCloseModal}
                activeOperation={this.state.activeOperation}
                selecteditem={this.state.selecteditem}
                checkedDeviceLimit={this.checkedDeviceLimit}
              />
            ) : this.props.match.params.id ? (
              <MainUnitModal
                id={this.props.match.params.id}
                history={this.props.history}
                hash={this.props.location.hash}
              />
            ) : (
              <EmptyState text={this.props.translate('noTrackersSelected')} />
            )}
          </div> : null}
          {/* {this.state.editResource ?  */}
          <ResourceModal
            changeResource={this.props.changeResource}
            selectedResourse={this.state.selecteditem}
            activeOperation={this.state.activeOperation}
            fetchNestedItems={this.props.fetchNestedItems}
            itemPagination={this.props.devices3}
            assignItem={this.props.assignItem}
            unassignItem={this.props.unassignItem}
            nestedResources={this.props.nestedResources}
            translate={this.props.translate}
            editResource={this.state.editResource}
            linkResource={this.state.linkResource}
            onCloseResource={this.onCloseResource}
            themecolors={this.props.themecolors}
            itemType='Device'
            title='device'
          />
          {/* //  : null} */}
        </Layout>
      )
    } else {
      return null
    }
  }
}

const mapState = state => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  positions: state.positions,
  devices: state.devices,
  devices3: state.devices3,
  themecolors: state.themeColors
})

Units.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = connect(mapState)

export default mapStateToProps(withSnackbar(withLocalize(withStyles(styles)(withResources(Units, 'Device')))))