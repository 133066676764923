import React, { Component } from 'react'
import { withLocalize } from 'react-localize-redux'
import Layout from './../../Layout'
import VehicleTagSelector from './../../Layout/Sidebar/Components/VehicleFilterModal'
import DriverTagSelector from './../../Layout/Sidebar/Components/DriverFilterModal'
import DrawMap from './../../Components/Maps/DrawMap'
import { checkPrivileges, errorHandler } from '../../Helpers'
import {
  unsetDeviceId,
  resetFilters,
  prepareDriverTags,
  resetGroupBy
} from '../../Actions/Devices'
import { getGroups, saveGroupWiseDevices } from '../../Actions/Groups'
import { connect } from 'react-redux'
import './Style.scss'
import axios from 'axios'
import Notifications from 'react-notification-system-redux'
import TrackingBox from '../../Components/common/TrackingBox'
import ResourceModal from '../../Components/Recources/resourceModal'
import withResources from '../HOCRecources'
import instance from '../../axios'
import moment from 'moment'

const options = {
  width: 250,
  margin: 10
}

const maxBoxes = 20;

const CancelToken = axios.CancelToken
let source
class Monitoring extends Component {
  constructor (props) {
    super(props)
    this.state = {
      userInfoFront: {},
      userFetch: false,
      userId: props.logInUser && props.logInUser.id ? props.logInUser.id : 0,
      initFetch: false,
      prepareTags: false,
      vehicleModal: false,
      driverModal: false,
      resourceList: false,
      linkResource: false,
      selecteditem: '',
      showAll:false,
      pageResource:1,
      limitResource:50,
      currentPage2: 1,
      pageSize2: -1,
      itemPagination2: {
        isFetching: true,
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText2: ''
      },
      boxes: [],
      sensorsDesc:[],
      showMonitoringTracks : false,
      routes : {},
      listType:"geofences"


    }
    this.map = null
  }

  showResources = (type) => {
    if(!type) {
      this.props.dispatch(saveGroupWiseDevices([]))
      this.props.dispatch(resetFilters())
    }
    this.setState({
      resourceList: type
    })
  }

  showBox = ({id, name}) => {
    const boxes1 = this.state.boxes.filter(box => box.id !== id);
    const boxes = boxes1.filter((e, index) => (index + 1) < maxBoxes);
    
    this.setState({ boxes: [...boxes, { id, name, ...options }] });
  }

  closeBox = (id, event) => {
    event.stopPropagation()
    this.setState({boxes: this.state.boxes.filter(box => box.id !== id)});
  } 

  componentWillMount () {
    this.props.dispatch(saveGroupWiseDevices([]))
    this.props.dispatch(resetFilters())
    if (this.props.logInUser && this.props.logInUser.id) {
      this.fetchData2(
        this.state.pageResource,
        this.state.limitResource,
        this.props.logInUser,
      )
    }
  }

  setMapRef = ref => {
    this.map = ref
  }

  displayError = e => {
    if (e) {
      // e.text().then(err => {
      //   console.log(err)
      // })
      console.log(e)
    }
  }

  componentWillReceiveProps (n) {
    if (n.logInUser && n.logInUser.id && this.state.initFetch === false) {
      this.setState(
        {
          initFetch: true
        },
        () => {
          this.fetchData2(
            this.state.pageResource,
            this.state.limitResource,
            this.props.logInUser,
          )
        }
      )
    }
  }
  openDeviceSettings = deviceId => {
    this.props.history.push('/units/' + deviceId)
  }

  fetchMoreItems2 = (a, b, c) => {
    this.setState(
      {
        searchText2: ''
      },
      () => {
        this.fetchData2(
          this.state.pageResource,
          this.state.limitResource,
          this.props.logInUser,
          true
        )
      }
    )
  }

  removeDuplicates = (n, o) => {
    let items = []
    o.map(j => {
      let found = n.data.find(e => e.id === j.id)
      if (found) {
        items.push(found)
      }
      return null
    })

    if (!items.length) {
      this.setState(
        {
          itemPagination2: {
            ...n,
            isFetching: false,
            items: o.concat(n.data)
          },
          currentPage2: n.hasNext ? n.page + 1 : n.page
        },
        () => {
          this.props.dispatch(getGroups(o.concat(n.data)))
        }
      )
    }
  }
  switchToResource=()=>{
    this.setState((prevState)=>({
      ...prevState,
      pageResource:1,
      limitResource:50
    }))
  }
  fetchData2 = (page, limit, logInUser, reset = false) => {
    if(source) {
      source.cancel("");      
    }

    source = axios.CancelToken.source();
  
    let searchText = this.state.searchText2 || '';
    
    if (reset || searchText) {
      this.setState({
        itemPagination2: { ...this.state.itemPagination2, items: [] },
        loading: true
      });
    }

    instance({
      url: `/api/groups/get`,
      method: 'GET',
      cancelToken: source.token,
      params: {
        page: page,
        limit: limit,
        search: searchText
      }
    })
      .then(response => {
        const totalGroupsData = response?.total;
        const getGroupsData = response?.data;
  
        if (getGroupsData) {
          this.setState(prevState => {
            const combinedGroups = reset || searchText
              ? getGroupsData
              : [...prevState.itemPagination2.items, ...getGroupsData];
            const uniqueGroups = combinedGroups.filter((group, index, self) =>
              index === self.findIndex((g) => g.id === group.id)
            );
  
            return {
              itemPagination2: {
                ...prevState.itemPagination2,
                items: uniqueGroups
              },
              loading: false,
              hasMore: uniqueGroups.length < totalGroupsData,
            };
          }, () => {
            this.props.dispatch(getGroups(this.state.itemPagination2?.items));
          });
  
        } else {
          this.setState({
            loading: false,
            hasMore: false,
          });
        }
  
      }).catch(error => {
        console.error(error);
      });
  }
  handleScrollResources = (values) => {
    const { scrollTop, clientHeight, scrollHeight } = values;
    const { page, limitResource, hasMore } = this.state;
    const { logInUser } = this.props;
    if (hasMore) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.setState(
          (prevState) => {
            const nextPage = prevState.pageResource + 1;
            return { pageResource: nextPage };
          },
          () => {
            this.fetchData2(this.state.pageResource, limitResource, logInUser);
          }
        );
      }
    }
  }
  searchItems2 = (text) => {
    this.setState(
      {
        searchText2: text,
        pageResource: 1,
      },
      () => {
        this.fetchData2(this.state.pageResource, this.state.limitResource, this.props.logInUser, true);
      }
    );
  }

  componentWillUnmount () {
    this.props.dispatch(unsetDeviceId(0))
    this.props.dispatch(resetFilters())
    this.props.dispatch(saveGroupWiseDevices([]))
    if (source) {
      source.cancel()
    }
    this.setState({
      userInfoFront: {},
      userFetch: false,
      userId: 0,
      initFetch: false,
      vehicleModal: false,
      driverModal: false,
      resourceList: false,
      linkResource: false,
      selecteditem: '',

      currentPage2: 1,
      pageSize2: -1,
      itemPagination2: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText2: ''
      }
    })
  }

  copyToClipboard = (obj,chk) => {
    const el = document.createElement('textarea')
    chk ?el.value = obj :el.value = `${obj.latitude},${obj.longitude}`
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    this.props.dispatch(
      Notifications.success({
        message: 'Copied to clipboard!',
        autoDismiss: 10
      })
    )
  }

  openVehicleTagsModal = () => {
    this.setState({
      vehicleModal: true
    })
  }

  openDriverTagsModal = () => {
    this.setState({
      driverModal: true
    })
  }

  cancelFilter = () => {
    this.setState({
      vehicleModal: false,
      driverModal: false
    })
  }

  saveFilter = () => {
    this.setState({
      vehicleModal: false,
      driverModal: false
    })
  }

  onLinkResource = (item) =>{
    this.setState({
      linkResource: true,
      selecteditem: item,
    },()=>{this.props.fetchNestedItems(item.id,1)})
  }
  onCloseResource = () =>{
    this.setState({
      linkResource: false,
      selecteditem: ''
    });
  }

  getSensorsData = (id) =>{
       instance({
        url: `api/attributes/computed`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params :{
          deviceId: id ,
          // limit: 4,
          category:"all",
          all:true
        }
      })
      .then(
        response => {
          if (response) {
            this.setState({
              sensorsDesc:response
            })
            // response.json()
            // .then(events => {
            //   this.props.dispatch(updateEvents(events.data))
            // })
          }
          else{
           console.log("error")
          }
        }).catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
  }
  showAllTooltTip = ()=> {
     this.setState({showAll:!this.state.showAll})
  }  

  // Add this function to get the timezone
  getTimezone = () => {
    let timezone = 'Asia/Dubai';
  
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone;
    }
  
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone;
    }
  
    return timezone;
  };

  hideTracks =() =>{
    this.setState({showMonitoringTracks:false, routes:{}})
  }



  showTracks = (deviceId) => {
    this.setState({
      showMonitoringTracks : true,
    })

    const timezone = this.getTimezone();

    moment.tz.setDefault(timezone);

    const todayStart = moment().startOf('day');
    const todayEnd = moment().endOf('day');

    const formatedDate = {
        from: todayStart.toISOString(),
        to: todayEnd.toISOString()
    };

    this.setState({
      showMonitoringTracks : false,
      tracksLoader:true,
    }, () => {
        let form = {
            to: todayEnd.toISOString(),
            from: todayStart.toISOString(),
            deviceIds: [deviceId?.id?.toString()] || [],
            itemGroupIds:  [],
            detail: false,
            types: [],
            mail: false
        };

        instance({
            url: `/api/reports/tracks?from=${formatedDate.from}&to=${formatedDate.to}&deviceId=${deviceId?.id}`,
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            // data: {...form}
        })
        .then(response => {
            let  obj = response || {}
            const transformedObj = {};

            for (const key in obj) {
              if (obj.hasOwnProperty(key)) {
                if (obj[key].length === 0) {
                  this.props.dispatch(
                    Notifications.success({
                      message: `No tracks found for vehicle ${deviceId.name}`,
                      autoDismiss: 10
                    })
                  )
                } else {
                  transformedObj[key] = {};
                  obj[key].forEach((item, index) => {
                    transformedObj[(index + 1).toString()] = { visible: true, positions: item.positions, row: item.track };
                  });
                }
              }
            }
          
              this.setState({
                showMonitoringTracks : Object.keys(transformedObj).length > 0,
                tracksLoader:false,
                routes : transformedObj||[]
              })
            }
          )
        .catch(error => {errorHandler(error, this.props.dispatch)});
    });
}
showListType = (type) => {
  this.setState({
    listType: type
  })
}
  render () {
    if (checkPrivileges('device')) {
      const endMessage =
        this.props.itemPagination && this.props.itemPagination.total > 0 ? (
          <p style={{ textAlign: 'center', color: '#ccc' }}>
            -- {this.props.translate('end')} --
          </p>
        ) : (
          <p style={{ textAlign: 'center', color: '#ccc' }}>
            {this.props.translate('notFound')}
          </p>
        )
      const {devices3, ...resetProps}  = this.props;
      return (
        <Layout
          {...resetProps}
          sensorsDesc={this.state.sensorsDesc}
          monitoringMap={this.map}
          openDeviceSettings={this.openDeviceSettings}
          classFromChildren='main-view'
          //showDeviceModal={this.state.showDeviceModal}
          getSensorsData={this.getSensorsData}
          fetchMoreItems={this.fetchMoreItems}
          showBox={this.showBox}
          openVehicleTagsModal={this.openVehicleTagsModal}
          openDriverTagsModal={this.openDriverTagsModal}
          itemPagination={{ ...this.state.itemPagination, endMessage }}
          searchItems={this.searchItems}
          fetchMoreItems2={this.fetchMoreItems2}
          itemPagination2={{ ...this.state.itemPagination2 }}
          searchItems2={this.searchItems2}
          resourceList={this.state.resourceList}
          showResources={this.showResources}
          onLinkResource={this.onLinkResource}
          copyToClipboard={this.copyToClipboard}
          showAllTooltTip={this.showAllTooltTip}
          noSidebar={this.state.showMonitoringTracks}
          handleScrollResources={this.handleScrollResources}
          switchToResource={this.switchToResource}
          
        >
          <ResourceModal
            changeResource={this.props.changeResource}
            selectedResourse={this.state.selecteditem}
            activeOperation={this.state.activeOperation}
            onCloseResource={this.onCloseResource}
            itemPagination={devices3}
            assignItem={this.props.assignItem}
            unassignItem={this.props.unassignItem}
            fetchNestedItems={this.props.fetchNestedItems}
            nestedResources={this.props.nestedResources}
            translate={this.props.translate}
            linkResource={this.state.linkResource}
            themecolors={this.props.themecolors}
            itemType='Device'
            title='device'
          />
          <TrackingBox boxes={this.state.boxes} translate={this.props.translate} logInUser={this.props.logInUser} copyToClipboard={this.copyToClipboard} closeBox={this.closeBox} />
          
          <DrawMap
            {...resetProps}
            listType={this.state.listType}
            routes={this.state.routes}
            showMonitoringTracks={this.state.showMonitoringTracks}
            showTracks={this.showTracks}
            hideTracks={this.hideTracks}
            setMapRef={this.setMapRef}
            devices={this.props.devices.data}
            copyToClipboard={this.copyToClipboard}
            showAll={this.state.showAll}
            vehicles={this.props.vehicles}
            showListType={this.showListType}
          />

          {this.state.vehicleModal ? (
            <VehicleTagSelector
              userTags={this.props.logInUser.attributes}
              tags={this.props.tags}
              vehicleModal={this.state.vehicleModal}
              translate={this.props.translate}
              dispatch={this.props.dispatch}
              saveFilter={this.saveFilter}
              cancelFilter={this.cancelFilter}
            />
          ) : null}
          {this.state.driverModal ? (
            <DriverTagSelector
              userTags={this.props.logInUser.attributes}
              tags={this.props.driverTags}
              driverModal={this.state.driverModal}
              translate={this.props.translate}
              dispatch={this.props.dispatch}
              saveFilter={this.saveFilter}
              cancelFilter={this.cancelFilter}
            />
          ) : null}
        </Layout>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = state => {
  return {
    devicesFetched: state.devices.isFetching,
    devices: state.devices,
    devices3: state.devices3,
    trailers: state.trailers,
    tags: state.tags,
    driverTags: state.driverTags,
    allNotifications: state.allNotifications,
    deviceId: state.deviceId,
    trackId: state.trackId,
    drivers: state.drivers,
    ServerSetting: state.ServerSetting,
    mapLayer: state.mapLayer,
    logInUser: state.logInUsers,
    themecolors: state.themeColors,
    filterTagsList: state.filterTagsList,
    filterDriversList: state.filterDriversList,
    vehicles:state.vehicles,
    filterList:
      state.filterList && state.filterList.filters
        ? state.filterList.filters
        : []
  }
}

export default connect(mapStateToProps)(withLocalize(withResources(Monitoring, 'Device')))
